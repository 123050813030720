<template>
  <div class="container">
    <div class="header"><Header></Header></div>
    <div class="slider-main">
      <div class="slider"><Slider></Slider></div>
      <div class="main">
        <transition mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "./Header.vue/index";
import Slider from "./Slider.vue/index";
export default {
  components: {
    Header,
    Slider,
  },
  computed: {
    ...mapState(["locale"]),
  },
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.slider-main {
  display: flex;
  width: 100%;
  flex: 1;
}
.slider {
  /* background-color: #915BB6; */
  background-color: #2f3e4f;
}
.main {
  flex: 1;
  padding: 1.17rem 1.67rem;
  width: 0;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.5s;
}
.v-enter {
  transform: translateX(-20px);
  opacity: 0;
}
.v-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>