import { post, get, getToken } from './request'
// 登录
export const login = params => post('/erp/user/login', params)


// 添加用户
export const addUser = params => post('/erp/user/add', params)
// 删除用户
export const delUser = params => post('/erp/user/delete', params)
// 获取用户列表
export const getUserList = params => post('/erp/user/list', params)
// 编辑用户
export const editUser = params => post('/erp/user/update', params)
// 获取用户时间币流水
export const getTimeMoneyRecord = params => post('/erp/user/timemoneylist', params)
// 导入用户
export const importUser = params => post('/erp/user/import', params)

// 获取求助语音
export const getVoice = params => post('/erp/help/voice/list', params)
// 删除求助语音
export const delVoice = params => post('/erp/help/voice/delete', params)


// 添加商品
export const addGood = params => post('/erp/goods/add', params)
// 修改商品信息
export const changeGood = params => post('/erp/goods/update', params)
// 修改商品上下线状态
export const changeGoodStatus = params => post('/erp/goods/change', params)
// 删除商品
export const delGood = params => post('/erp/goods/delete', params)
// 获取商品列表
export const getGoodsList = params => post('/erp/goods/list', params)
// 获取商品兑换列表
export const getGoodsExchangeList = params => post('/erp/goods/exchange/list', params)
// 商品列表补录
export const goodsAddPeople = params => post('/erp/goods/goodImport', params)

// 获取组织列表
export const getGroupList = params => post('/erp/group/list', params)
// 添加组织
export const addGroup = params => post('/erp/group/add', params)
// 组织编辑
export const changeGroup = params => post('/erp/group/update', params)
// 获取组织树
export const getGroupTree = (params = {}) => post('/erp/group/tree', params)
// 获取关联管理员列表
export const getGroupAdminList = params => post('/erp/group/admin/list', params)
// 管理员身份证模糊查询
export const queryAdmin = params => post('/erp/group/admin/query', params)
// 关联/不关联管理员
export const linkAdmin = params => post('/erp/group/admin/update', params)
// 关联/不关联管理员
export const groupDisable = params => get('erp/group/disable', params)

// 获取活动列表
export const getActivityList = params => post('/erp/activity/list', params)
// 编辑活动
export const editActivity = params => post('/erp/activity/update', params)
// 新增活动
export const addActivity = params => post('/erp/activity/add', params)
// 删除活动
export const delActivity = params => post('/erp/activity/delete', params)
// 获取活动参与列表
export const getJoinList = params => post('/erp/activity/joinlist', params)

// 获取省数据
export const getProvince = (params = {}) => post('/erp/area/province', params)
// 获取市、区数据
export const getCityOrDistrict = params => post('/erp/area/sub', params)

// 获取加入组织审核列表
export const getAddGroupCheckList = params => post('/erp/applygroup/list', params)
// 审核
export const addGroupCheck = params => post('/erp/applygroup/updatestatus', params)

// 获取账户列表
export const getAccountList = params => post('/erp/user/accountlist', params)
// 新增机构账号
export const addOrg = params => post('/erp/user/addAdmin', params)
// 活动参与列表补录
export const addPeople = params => post('/erp/activity/activityImport', params)

// 获取7牛云token 
export const getSevenNiuToken = url => getToken(url + 'webproject/api/comm/currency.do')
// 轮播图列表
export const getBannersList = params => post('/erp/rotation/list', params)
// 删除轮播图
export const delBanner = params => post('/erp/rotation/batchRemove', params)
// 新增或编辑轮播图
export const saveBanner = params => post('/erp/rotation/add', params)
export const changeBannerStatus = params => post('/erp/rotation/add', params)



export const getOptions = params => post('/erp/dictionary/query', params)

export const saveOption = params => post('/erp/dictionary/save', params)

export const delOption = params => post('/erp/dictionary/delete', params)

export const getRecordList = params => post('/erp/dataManager/timeMoney/list', params)
export const getGroupRecordList = params => post('/erp/dataManager/timeMoney/groupList', params)

export const getStats = params => post('/erp/dataManager/groupData/list', params)

export const getMomentList = params => post('/erp/moment/styleWallList', params)

export const delMoment = params => post('/erp/moment/deletedStyle', params)

export const zxt = params => post('/erp/dataManager/groupData/tableList', params)

export const downloadActivityResult = params => post('/erp/activity/joinListExport', params,{
    responseType: 'blob'
})
export const getActivityResult = params =>get('/erp/activity/getjoinResult',params)
export const saveActivityResult = params =>post('/erp/activity/joinResultSave',params)

//回退按钮
export const huiTui = params=>post('/erp/activity/joinBack',params)
