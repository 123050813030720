import axios from 'axios'
import QS from 'qs'
import { Form, message } from 'ant-design-vue'
import Router from '@/router/index'
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = "/api"
} else if (process.env.NODE_ENV === 'production') {
    // 这里应该换成真实后端地址
    axios.defaults.baseURL = "https://www.lktimebank.com/lktimebank"

} else if (process.env.NODE_ENV === 'debug') {
    axios.defaults.baseURL = ""
}
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post.accept = '*/*'

axios.interceptors.request.use(config => {
    const xtoken = localStorage.getItem('xtoken')
    config.headers['x-speed-token'] = xtoken
    return config
})
axios.interceptors.response.use(response => {
    if (response.data.status == 2) {
        message.error('登录失效,请重新登录')
        localStorage.removeItem('xtoken')
        Router.push({ name: 'login' })
    }
    if (response.data.status == -1) {
        message.error(response.data.msg)
    }
    if (response.headers['x-speed-token']) {
        window.localStorage.setItem('xtoken', response.headers['x-speed-token'])
    }
    if (response.headers['X-Speed-Token']) {
        window.localStorage.setItem('xtoken', response.headers['X-Speed-Token'])
    }
    return response
})

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: params })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

export function post(url, params,config={}) {
    return new Promise((resolve, reject) => {
        axios.post(url, JSON.stringify(params),config)
            .then(res => {

                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

export function getToken(url) {
    const myAxios = axios.create({
        headers: { 'Content-Type': 'application/x-www-from-urlencoded' }
    })
    var params = new FormData();
    params.append('api', '/api/token/getToken.do')
    params.append('apimode', 'xsdwmsj')
    params.append('apidata', "{}")
    return new Promise((resolve, reject) => {
        myAxios.post(url, params)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}