<template>
  <div class="header">
    <div class="header_left">
      <div class="logo_box">
        <img src="@/assets/logonew.jpg" alt="" class="logo" @click="toIndex" />
      </div>
    </div>
    <div class="header_right">
      <div class="user">
        <img src="@/assets/defaultAvatar.png" alt="" />
        <a-popover placement="bottom">
          <template slot="content">
            <span style="cursor:pointer" @click="logout">退出登录</span>
          </template>
          <a>{{nowAccount}}</a>
        </a-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data(){
    return{
      nowAccount:""
    }
  },
  created(){
    this.nowAccount = localStorage.getItem("nowAccount")
  },
  methods:{
    toIndex(){
      this.$router.push({name:'user'})
    },
    logout(){
      localStorage.removeItem('xtoken')
      localStorage.removeItem('nowAccount')
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  height: 3.33rem;
  display: flex;
  background-color: #ffffff;
  // background-color:#171332;
  justify-content: space-between;
  width: 100%;
  line-height: 3.33rem;
  .header_right {
    display: flex;
    justify-content: space-around;
    margin-right: 40px;
    .user {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 1.67rem;
        height: 1.67rem;
        vertical-align: middle;
      }
    }
  }
  .header_left {
    font-size: 1rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    .logo_box {
      height: 100%;
      width: 14rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo {
        height: 100%;
        cursor: pointer;
      }
    }
  }
}
.a-select {
  border: none;
}
.google_state {
  width: 34rem;
  font-size: 15px;
}
.google_true,
.google_false {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.google_true p {
  flex: 1;
  padding-left: 2rem;
}
.google_false p {
  padding-left: 2rem;
  flex: 1;
}
.google_true .a-checkbox {
  border-radius: 50%;
  background-color: #4ae387 !important;
}
.google_false .a-checkbox {
  /* background-color: #fff !important; */
  border-radius: 50%;
}
.unbunding_btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.display_state {
  margin-bottom: 3rem;
}
.ma {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
}
.ma img {
  width: 150px;
}
.display_3 {
  font-size: 40px;
  margin: 5px;
  text-align: center;
}
.one_btns {
  text-align: center;
  margin-top: 20px;
  button {
    margin-left: 1rem;
  }
}
.copy_box {
  text-align: center;
  margin-top: 10px;
}
.copy_box input {
  outline: none;
  border: none;
  border: 1px solid #e4e4e4;
  height: 1.9rem;
  margin-right: 20px;
  border-radius: 5px;
  color: rgb(121, 94, 218);
  width: 12rem;
  text-align: center;
  border-radius: 6px;
}
.copy_box button {
  outline: none;
  border: none;
  border: 1px solid rgb(121, 94, 218);
  height: 30px;
  width: 60px;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  padding: 2px 0 2px 0;
  box-sizing: border-box;
  height: 1.9rem;
}
.copy_box button:hover {
  background-color: rgb(121, 94, 218);
  color: #fff;
}
.input_grop input {
  width: 46px;
  height: 50px;
  margin-left: 7px;
  margin-top: 26px;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  line-height: 50px;
  font-weight: 800;
}
.input_grop {
  text-align: center;
}
.enter_password {
  text-align: center;
}
.guge_img {
  width: 145px !important;
}

.twoStep p:nth-of-type(2) {
  margin: 10px;
  font-size: 12px;
  text-align: center;
}
.threeStep button {
  margin: 24px 0px 0 219px;
  display: block;
}
.copy {
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.firstStep p {
  text-align: center;
}
.ant-modal-content {
  width: 42rem !important;
}
.ant-modal {
  width: 0 !important;
}
.modal-title {
  font-size: 40px;
  text-align: center;
}
.modal-context {
  text-align: center;
}
.modal-button {
  width: 180px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
</style>