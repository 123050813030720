<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import { mapState } from "vuex";

import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      zh_CN,
    };
  },

  computed: {
    ...mapState(["locale"]),
  },
};
</script>


<style>
#app {
  height: 100%;
  background-color: #f5f7fa;
}
</style>
