import { Tree, Spin, Input, Radio, Select, message, Layout, Menu, Icon, Button, Card, ConfigProvider, DatePicker, Tag, Tabs, Dropdown, Pagination, Table, Form, FormModel, Popover, Tooltip, Row, Col, Breadcrumb, Modal, Checkbox, Empty, Switch, Upload, Statistic, Divider, Popconfirm, Collapse, Descriptions, TreeSelect, TimePicker, Steps,Result, InputNumber } from 'ant-design-vue';


message.config({
    duration: 2,
    maxCount: 2
})
const antdv = {
    install: function (Vue) {
        Vue.prototype.$message = message
        Vue.prototype.$confirm = Modal.confirm
        Vue.use(Steps)
        Vue.use(Result)
        Vue.use(Input)
        Vue.use(Radio)
        Vue.use(Select)
        Vue.use(Layout)
        Vue.use(Menu)
        Vue.use(Icon)
        Vue.use(Button)
        Vue.use(Card)
        Vue.use(ConfigProvider)
        Vue.use(DatePicker)
        Vue.use(Tag)
        Vue.use(Tabs)
        Vue.use(Dropdown)
        Vue.use(Pagination)
        Vue.use(Table)
        Vue.use(Form)
        Vue.use(FormModel)
        Vue.use(Popover)
        Vue.use(Tooltip)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(Breadcrumb)
        Vue.use(Modal)
        Vue.use(Checkbox)
        Vue.use(Empty)
        Vue.use(Switch)
        Vue.use(Upload)
        Vue.use(Statistic)
        Vue.use(Divider)
        Vue.use(Popconfirm)
        Vue.use(Collapse)
        Vue.use(Descriptions)
        Vue.use(Spin)
        Vue.use(Tree)
        Vue.use(TreeSelect)
        Vue.use(TimePicker)
        Vue.use(InputNumber)
    }
}

export default antdv