import Vue from 'vue'
import VueRouter from 'vue-router'
import appLayout from '@/layout'
Vue.use(VueRouter)
// 对原型上push方法进行错误捕捉
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/admin",
    component: appLayout,
    redirect: "/admin/user",
    children: [
      {
        path: "user",
        name: "user",
        component: () => import("@/views/user")
      },
      {
        path: "dep",
        name: "dep",
        component: () => import("@/views/dep")
      },
      {
        path: "activity",
        name: "activity",
        component: () => import("@/views/activity")
      },
      {
        path: "good",
        name: "good",
        component: () => import("@/views/good")
      },
      {
        path: "check",
        name: "check",
        component: () => import("@/views/check")
      },
      {
        path: "sound",
        name: "sound",
        component: () => import("@/views/sound")
      },
      {
        path: "banner",
        name: "banner",
        component: () => import("@/views/banner"),
      },
      {
        path: "moments",
        name: "moments",
        component: () => import("@/views/moments"),
      },
      {
        path: "dataDict",
        name: "dataDict",
        component: () => import("@/views/dataDict"),
      },
      {
        path: "stats",
        name: "stats",
        component: () => import("@/views/stats"),
      },
      {
        path: "timeCoin",
        name: "timeCoin",
        component: () => import("@/views/timeCoin"),
      },
    ]
  },
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: () => import('@/views/login')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    // document.title = `${to.meta.name} | Tulipex CONSOLE`
    next()
  } else {
    if (localStorage.getItem('xtoken')) {
      // document.title = `${to.meta.name} | Tulipex CONSOLE`
      next()
    } else {
      // document.title = `${to.meta.name} | Tulipex CONSOLE`
      next({ name: "login" })
    }
  }
})

export default router
