<template>
  <div style="width: 14rem">
    <a-menu
      :selectedKeys="[this.$route.path]"
      mode="inline"
      :inline-collapsed="collapsed"
    >
      <template v-for="(item, index) in menu">
        <a-sub-menu v-if="item.children.length" :key="index">
          <span slot="title">
            <a-icon v-if="item.icon" :type="item.icon"></a-icon>
            <span>{{ item.title }}</span>
          </span>
          <template v-for="(item, cindex) in item.children">
            <a-sub-menu v-if="item.children.length" :key="index + '-' + cindex">
              <span slot="title">
                <a-icon v-if="item.icon" :type="item.icon"></a-icon>
                <span>{{ item.title }}</span>
              </span>
              <template v-for="(item, ccindex) in item.children">
                <a-menu-item :key="index + '-' + cindex + ccindex">
                  <router-link :to="item.path">
                    <a-icon v-if="item.icon" :type="item.icon"></a-icon>
                    <span>{{ item.title }}</span>
                  </router-link>
                </a-menu-item>
              </template>
            </a-sub-menu>
            <a-menu-item v-else :key="index + '-' + cindex">
              <router-link :to="item.path">
                <a-icon v-if="item.icon" :type="item.icon"></a-icon>
                <span>{{ item.title }}</span>
              </router-link>
            </a-menu-item>
          </template>
        </a-sub-menu>
        <a-menu-item v-else :key="item.path">
          <router-link :to="item.path">
            <a-icon v-if="item.icon" :type="item.icon"></a-icon>
            <span>{{ item.title }}</span></router-link
          >
        </a-menu-item>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { getGroupTree } from "@/network/api.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      collapsed: false,
      menu: [
        {
          icon: "user",
          title: "用户管理",
          children: [],
          path: "/admin/user",
        },
        {
          icon: "deployment-unit",
          title: "组织管理",
          children: [],
          path: "/admin/dep",
        },
        {
          icon: "schedule",
          title: "活动管理",
          children: [],
          path: "/admin/activity",
        },
        {
          icon: "inbox",
          title: "商品管理",
          children: [],
          path: "/admin/good",
        },
        {
          icon: "audit",
          title: "加入组织审核",
          children: [],
          path: "/admin/check",
        },
        {
          icon: "sound",
          title: "求助语音",
          children: [],
          path: "/admin/sound",
        },
        {
          icon: "appstore",
          title: "风采墙管理",
          children: [],
          path: "/admin/moments",
        },
        {
          icon: "profile",
          title: "统计管理",
          children: [],
          path: "/admin/stats",
        },
        {
          icon: "dollar",
          title: "时间币流水查询",
          children: [],
          path: "/admin/timeCoin",
        }
      ],
    };
  },
  mounted() {
    getGroupTree().then((res) => {
      if (res.data[0].id == 1) {
        this.menu = [
          {
            icon: "user",
            title: "用户管理",
            children: [],
            path: "/admin/user",
          },
          {
            icon: "deployment-unit",
            title: "组织管理",
            children: [],
            path: "/admin/dep",
          },
          {
            icon: "schedule",
            title: "活动管理",
            children: [],
            path: "/admin/activity",
          },
          {
            icon: "inbox",
            title: "商品管理",
            children: [],
            path: "/admin/good",
          },
          {
            icon: "audit",
            title: "加入组织审核",
            children: [],
            path: "/admin/check",
          },
          {
            icon: "sound",
            title: "求助语音",
            children: [],
            path: "/admin/sound",
          },
          {
            icon: "appstore",
            title: "风采墙管理",
            children: [],
            path: "/admin/moments",
          },
          {
            icon: "profile",
            title: "统计管理",
            children: [],
            path: "/admin/stats",
          },
          {
            icon: "dollar",
            title: "时间币流水查询",
            children: [],
            path: "/admin/timeCoin",
          },
          {
            icon: "read",
            title: "数据字典",
            children: [],
            path: "/admin/dataDict",
          },
          {
            icon: "picture",
            title: "轮播图管理",
            children: [],
            path: "/admin/banner",
          },
        ];
      }
      this.formatGroupTree(res.data);
      this.setGroupTree(res.data);
    });
  },
  computed: {
    ...mapState(["groupTree"]),
  },
  methods: {
    ...mapMutations(["setGroupTree"]),
    formatGroupTree(rawData) {
      rawData.forEach((item) => {
        item.title = item.name;
        item.key = item.id;
        item.value = item.id;
        item.children = item.childList;
        delete item.childList;
        delete item.id;
        delete item.parentId;
        delete item.name;
        if (item.children.length) {
          this.formatGroupTree(item.children);
        }
      });
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style>
.ant-menu-item:first-of-type {
  margin-top: 0 !important;
}
.ant-menu.ant-menu-inline.ant-menu-root.ant-menu-light {
  border-right: 0 !important;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100% !important;
}
/* .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #0b76ff !important;
} */
</style>