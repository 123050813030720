import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import antdv from './plugins/antdv-plugin'
import './style/index.css'
import VueExcelXlsx from "vue-excel-xlsx";
import "./assets/global.css"
import dayjs from "dayjs";
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview,{fullscreenEl: false})
require('dayjs/locale/zh-cn')
var relativeTime = require('dayjs/plugin/relativeTime')
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)
dayjs.extend(relativeTime)
dayjs.locale('zh-cn')
Vue.prototype.$dayjs = dayjs
Vue.use(VueExcelXlsx);
Vue.config.productionTip = false
Vue.use(antdv)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
